// src/pages/FinanceHome.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/FinanceHeader';
import FinanceExpertImage from '../assets/finance.jpg'; // Make sure you have an image in this path

const FinanceHome = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/signup');
  };

  return (
    <div>
      <Header />
      <div className="flex flex-col items-center justify-center min-h-screen">
        <section className="flex flex-col md:flex-row items-center justify-between text-left max-w-6xl mx-auto">
          <div className="md:w-1/2">
            <h1 className="text-6xl font-bold mb-4 text-gray-900">Get Help with Your Personal Finances.</h1>
            <p className="text-lg mb-5 text-gray-700">Attain answers to all your personal finance questions.</p>
            <button 
              onClick={handleButtonClick}
              className="bg-blue-500 text-white px-6 py-2 rounded-full hover:bg-blue-600 text-lg"
            >
              Get Started
            </button>
          </div>
          <div className="md:w-1/2 mt-8 md:mt-0 flex justify-center">
            <img src={FinanceExpertImage} alt="Personal Finance Expert" className="rounded-lg shadow-lg" />
          </div>
        </section>
        <section className="text-center mt-10 px-4">
          <h2 className="text-3xl font-semibold mb-4 text-gray-900">How It Works?</h2>
          <div className="text-lg text-gray-700 space-y-4">
            <p className="flex items-center justify-center">
              <span className="bg-blue-100 text-blue-500 font-bold rounded-full w-8 h-8 flex items-center justify-center mr-3">1</span>
              Sign Up
            </p>
            <p className="flex items-center justify-center">
              <span className="bg-blue-100 text-blue-500 font-bold rounded-full w-8 h-8 flex items-center justify-center mr-3">2</span>
              Get Matched with a Personal Finance Coach
            </p>
            <p className="flex items-center justify-center">
              <span className="bg-blue-100 text-blue-500 font-bold rounded-full w-8 h-8 flex items-center justify-center mr-3">3</span>
              Get Your Questions Answered
            </p>
          </div>
        </section>
      </div>
    </div>
  );
};

export default FinanceHome;





