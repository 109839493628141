// App.js
import React from 'react';
import { Provider } from 'react-redux';
import store from './store/store';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import FinanceHome from './pages/FinanceHome';
import SignUp from './pages/SignUp';

const App = () => {
  return (
      <Provider store={store}>
        <Router>
          <Routes>
            <Route path="/" element={<FinanceHome />} /> 
            <Route path="/signup" element={<SignUp />} /> 
          </Routes>
        </Router>
      </Provider>
  );
};

export default App;
