// src/pages/SignUp.js
import React, { useState } from 'react';
import { addToWaitlist } from '../services/api'; // Adjust the path as needed
import { useNavigate } from 'react-router-dom';

const SignUp = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await addToWaitlist(email);
      setMessage('Successfully added to the waitlist!');
      navigate('/');
    } catch (error) {
      setMessage('Failed to add to the waitlist. Please try again.');
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <h1 className="text-4xl font-bold mb-8">Sign Up</h1>
      <form onSubmit={handleSubmit} className="bg-white p-6 rounded shadow-md w-full max-w-md">
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
            Email:
          </label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>
        <button
          type="submit"
          className="bg-blue-500 text-white px-4 py-2 rounded-full hover:bg-blue-600 w-full"
        >
          Complete Sign Up
        </button>
      </form>
      {message && <p className="mt-4 text-lg text-gray-700">{message}</p>}
    </div>
  );
};

export default SignUp;
