import axios from 'axios';

const BASE_URL = 'https://booleanco.com/api';

const api = axios.create({
  baseURL: BASE_URL,
});

export const addToWaitlist = async (email) => {
  try {
    const response = await api.post('/waitlist/add', { email });
    return response.data;
  } catch (err) {
    throw new Error('Failed to add email to waitlist');
  }
};

export const getWaitlistEntries = async () => {
  try {
    const response = await api.get('/waitlist');
    return response.data;
  } catch (err) {
    throw new Error('Failed to fetch waitlist entries');
  }
};
